import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
名古屋大学の情報学部コンピュータ科学に所属する 3 年生です。
      <p>{`普段は CS 系のお勉強をしています(成績は良さめです)。`}<br />{`
「CS 系っていってもいろいろあるやろ」と思った方のために掘り下げると、アルゴリズムとか離散数学とか代数学とか機械学習とか、最適化(線型計画法など)とか自然言語処理とかです。実験で DHCP サーバを作ったり、いま(2019 年 11 月現在)は CPU(シングルサイクル RISC プロセッサ) を作ったりもしています。`}</p>
      <br />
      <p>{`昔から絵を描くのが好きで、色々やってきました(油絵描いたりろくろ回したりもしたことあります)。UI デザインやグラフィックスに関しては、苦手じゃない程度の感覚があると思います。結構前からイラスト垢的なものをやっていたりもしました🎨(最近は全然描けて無いですが)`}</p>
      <br />
      <p>{`最近はだいたいプログラミングをして過ごしています。ちょっと嘘です。実験レポートと課題に追われています😩。`}</p>
      <p>{`プログラミングといっても様々で、このサイトを作るために WebGL や React を触ったり、Rust で競技プログラミングをやったり、インターンで機械学習をしたり Go で何かしらの何かを書いたりしています。`}</p>
      <p>{`趣味で色々なものを作ったり遊んだりしています。現状フロントエンドの方が得意ですが、ロジックを考えるのが好きなのでサーバサイドのプログラミングもとても好きです。`}</p>
      <br />
      <p>{`新しい技術に触れること自体が好きで、色々な言語やフレームワーク・技術に手を出しているんですが、そのせいもあってだいたい初心者です。広く浅くじゃなく狭く深くを目指したいお年頃になっています。`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      